type TErrorKeyCode = Record<number, string>;

export const errorKeyCodes: TErrorKeyCode = {
  211405: 'cantTakeItem',
  5002: 'cantTakeItem',
  5005: 'invalidLinkSteam',
  5008: 'cantTakeItem',
  5009: 'tempCantTakeItem',
  5007: 'checkInventoryOpenForTake',
} as const;
